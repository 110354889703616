<template>
  <nav class="footer bg-dark text-white" :class="getLayoutFooterBg()">
    <br>
    <!-- SECCIONES PIE DE PAGINA -->
    <b-row style="margin: 10px;">
    </b-row>

    <div class="container-fluid d-flex flex-wrap justify-content-between text-center container-p-x pb-3">
      <div>
        <span class="footer-text font-weight-bolder">ANDI</span> ©
      </div>
    </div>
  </nav>
</template>
<style>
  .ctrlCentrado {
    max-width: 350px;
    width: 100%;
    text-align: center;
  }
</style>
<script>
import espTraduccion from '../idiomas/esp.json'
import ingTraduccion from '../idiomas/ing.json'

export default {
  name: 'app-layout-footer',
  data: () => ({
    idiomaSelec: 'ing',
    jsonIdioma: {}
  }),
  created () {
    var self = this
    // TRADUCCION INTERFAZ
    self.idiomaSelec = window.$cookies.get('idiomaSelec')
    self.idiomaSelec = self.idiomaSelec === null ? 'esp' : self.idiomaSelec
    self.traduccion()
  },
  methods: {
    traduccion () {
      var self = this
      if (self.idiomaSelec === 'esp') {
        self.jsonIdioma = espTraduccion
      }
      if (self.idiomaSelec === 'ing') {
        self.jsonIdioma = ingTraduccion
      }
    },
    getLayoutFooterBg () {
      return `bg-${this.layoutFooterBg}`
    },
    linkInstagram () {
      window.open('https://www.instagram.com/baazarneptune/')
    },
    linkFacebook () {
      window.open('https://www.facebook.com/baazarneptune')
    }
  }
}
</script>
