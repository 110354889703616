import Layout2 from '@/layout/Layout2'

export default [{
  path: '/proveedores',
  component: Layout2,
  children: [
    {
      path: 'agenda/',
      component: () => import('@/components/proveedores/agenda')
    },
    {
      path: 'servicios/',
      component: () => import('@/components/proveedores/servicios')
    },
    {
      path: 'proveedores/',
      component: () => import('@/components/proveedores/proveedores')
    },
    {
      path: 'listaServicios/',
      component: () => import('@/components/proveedores/listaServicios')
    },
    {
      path: 'login/',
      component: () => import('@/components/proveedores/login')
    },
    {
      path: 'inicio/',
      component: () => import('@/components/proveedores/inicio')
    },
    {
      path: 'clienteCita/',
      component: () => import('@/components/proveedores/clienteCita')
    },
    {
      path: 'sucursales/',
      component: () => import('@/components/proveedores/sucursales')
    },
    {
      path: 'configuracion/',
      component: () => import('@/components/proveedores/configuracion')
    },
    {
      path: 'miMembresia/',
      component: () => import('@/components/proveedores/miMembresia')
    },
    {
      path: 'misClientes/',
      component: () => import('@/components/proveedores/misClientes')
    }]
}]
