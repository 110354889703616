export default {
  methods: {
    formatoMoneda (val) {
      var num = parseFloat(val)
      return num.toFixed(2)
    },
    scrollToTop () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth' // Animación suave
      })
    },
    validarCorreo (correo) {
      const regexCorreo = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
      return regexCorreo.test(correo)
    },
    removerEspacios (valor) {
      return valor.replace(/\s+/g, '')
    },
    dosDigitosString (num) {
      /*
        Si envio '1', regresa '01'
      */
      return num.toString().padStart(2, '0')
    },
    comparaHoras (tiempo1, tiempo2) {
      // Convert times to numbers representing minutes past midnight
      const tiempoAMinutos = (time) => {
        const [hours, minutes] = time.split(':').map(Number)
        return hours * 60 + minutes
      }
      const tiempoMinutes1 = tiempoAMinutos(tiempo1)
      const tiempoMinutes2 = tiempoAMinutos(tiempo2)
      return tiempoMinutes1 > tiempoMinutes2
    }
  }
}
